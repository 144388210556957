import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { handleError, parseDate, printDate } from '../../../lib/common'
import { useNavigate } from 'react-router-dom';
import TodoItem from '../../Todo/TodoItem';
import './index.css';

export default function ReviewList(parameters) {
  const [loading, setLoading] = useState(true);
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [todos, setTodos] = useState([]);
  const [oldTodos, setOldTodos] = useState([]);
  const navigate = useNavigate();
  const { user } = useContext(parameters.context);
  
  const API = `${serverUrl}/this-week`;
  const imgBase = `${serverUrl}/imgs/`;
  const imageAPI = `${serverUrl}/type-icon?type=`;
  const scheduleAPI = `${serverUrl}/scheduled-tasks-data`;

  useEffect(() => {
    if(!user) return;
    let data = [];
    axios.get(API, {params:{groupid:user.lastGroupId}}).then(y=>{
      data = (y.data||[]).filter(W => W.statusId < 2).sort( x => parseDate(x.date) ).reverse();
      setOldTodos( (y.data||[]).filter(W => W.statusId == 2).sort( x => parseDate(x.date) ).reverse() );
      
      if(!user.lastGroup?.isDefault)
        return Promise.resolve( {data:[]});
      else
        return axios.get(scheduleAPI, {params:{groupid:user.lastGroupId}});
    }).then(z=>{
      data.push(...(z.data||[]));
      setTodos( data );
      setLoading(false);
    }).catch((e) => { 
      setLoading(false);
      handleError(e, navigate);
    });
  }, [API, user]); // Runs only once

  const updateId = (oldId, newId) => {
    // add the new todo to beginning of todos array
    let f = todos.find(x=>x.taskId == oldId);
    f.taskId = newId;
    console.log("new id after the check is ",newId);
    setTodos(todos);
  }

  const onViewTask = (e, item) =>  {
    navigate(`/time/type/${item.typeId}/${item.taskId}`, {state: (item.taskId < 0) ? item : null} );
  }
  const handleEdit = (e, item) => {
    e.preventDefault();
    if(!item)navigate(`/todo/type/1/0`);
    else navigate(`/todo/type/${item.typeId}/${item.taskId}`, {state: (item.taskId < 0) ? item : null} );
  }
  if (loading) {
    return (
      <div className="container">
        <div>Loading items...</div>
      </div>
      )
  } else {
    return (
      <div className="container">
        <div className='container-header'>
            <span>&nbsp;</span>
            <img alt='This Week' style={{display:'block', width:'50px', height:'50px'}} id='base64image' src={imgBase + 'calendar-week.svg'} />
            { user?.lastGroup?.public ? 
              <button className="todo-btn" type="button">
                <span>&nbsp;#&nbsp;</span>
              </button> :
              <button className="todo-btn" type="button" onClick={handleEdit}>
                <span>&nbsp;+&nbsp;</span>
              </button>}
        </div>
        <div>Todos</div>
        <div className='todo-container'>
        {todos.length > 0 ? 
            Array.from(todos).map(item => (
              <TodoItem data={item} userId={user.userId} key={item.taskId} updateId={updateId} showType={true} />
            ))
           : <>No todos found. Enjoy the rest of this week!!!</>
        }
        </div>
        <div style={{marginTop:'20px'}}>Old</div>
        <div className='todo-container'>
        {oldTodos.length > 0 ? 
          Array.from(oldTodos).map(item => (
            <div className="todo-item" key={item.taskId}>
              <div className="text-todo">
                  {item.isDeleted ? 
                    <label className='removed'>{ decodeURI( item.name)} {(item?.public) ? '★': '' } - {printDate(item.date,item.year)}</label> 
                    :
                    <label>{ decodeURI( item.name)} {(item?.public) ? '★': '' } - {printDate(item.date,item.year)}</label>
                  }
                  <img id='base64image' src={`${imageAPI}${item.typeId}` }  />
              </div>
              <div className="text-next">
                <button className="todo-btn"  type="button" onClick={(e) =>onViewTask(e,item)} >&rarr;</button>
              </div>
            </div>
          ))
        : <>No old todos found for this week.</>
        }
        </div>
      </div>
    )
  }
  
}
