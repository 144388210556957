import React, {useState, useEffect, useContext, useRef} from 'react'
import Comment from '../../Comment';
import { handleError, localeDate, nameNotation, colorNameByNotation, parseDate, compareDate, encodeCheck } from '../../../lib/common'
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function TodoForm(parameters) {
    const params = useParams();
    const navigate = useNavigate();
    let { state } = useLocation();
    const { user } = useContext(parameters.context);
    const commentForm = useRef();

    let emptyTask = {
      taskId: 0,
      groupId: user ? user.lastGroupId : 0,
      name: "",
      date: new Date(),
      float: 0,
      scheduleId: 0,
      statusId:1,
      typeId:params.type,
      description: '',
      modifiedOn: new Date(),
      public: false,
      comments: []
    };

    if(state){
      emptyTask.name = state.name;
      emptyTask.scheduleId = state.scheduleId;
      emptyTask.typeId = state.typeId;
      emptyTask.date = state.date;
    }

    const [todo, setTodo] = useState( emptyTask);
    const [name, setName] = useState( emptyTask.name);
    const [desc, setDesc] = useState( emptyTask.description);
    const [done, setDone] = useState( false);
    const [priv, setPrivate] = useState( !emptyTask.public);
    const [mute, setMuted] = useState( false);
    const [comments, setComments] = useState([]);

    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const taskAPI = `${serverUrl}/tasks-data?type=${params.type}&id=${params.id}`;
    const [loading, setLoading] = useState(true);
    const [noteUser, setNoteUser] = useState({userId:-1, name:'', isEndUser: true});

  useEffect(() => {
    if(!user) return;
    
    if(params.id && parseInt(params.id) > 0){
      axios.get(taskAPI, {params:{groupid:user.lastGroupId}}).then(async y=>{
        if(y.data && y.data.length > 0) {
          let toda = y.data[0];
          setTodo(toda);
          setName(decodeURI(toda.name));
          setDesc(decodeURI(toda.description));
          setDone(toda.statusId == 2);
          setPrivate(!toda.public);
          setComments( (toda.comments||[]).sort( x => parseDate(x.commentedOn) ) );

          noteUser.userId = user.userId;
          noteUser.name = user.name;
          noteUser.isEndUser = (toda.userId == user.userId);
          setNoteUser(noteUser);
        }
        setLoading(false);
      }).catch((e) => { 
        setLoading(false);
        handleError(e, navigate);
      });
    } 
    else {
      setLoading(false);
    }
  }, [user]); // Runs only once


    /**
     * @param {React.FormEvent<HTMLButtonElement>} e 
     */
    const handleSubmit = (e) => {
      e.preventDefault();

      // Invalid characters validation 
      e.target.children[0].classList.remove('err');
      e.target.children[1].classList.remove('err');
      if(/[{<[]+/.test(name)) { e.target.children[0].classList.add('err'); return; }
      if(/[{<[]+/.test(desc)) { e.target.children[1].classList.add('err'); return; }

      // Long name validation 
      todo.name = encodeCheck(name);
      if(todo.name.length > 250 ) { e.target.children[0].classList.add('err'); return; }

      todo.description=encodeCheck(desc);
      todo.statusId = done ? 2:1;
      todo.public = priv ? false: true;
      if(!todo.groupId) 
        todo.groupId = user ? user.lastGroupId : 0;// refresh bug fix
      setMuted(true);

      axios.post(`${serverUrl}/include-task`,todo).then(y=>{
        alert(`Todo ${todo.taskId <= 0 ? 'created' : 'updated' } successfully!`);

        todo.taskId = y.data.taskId;
        todo.modifiedOn = new Date();
        setMuted(false);
        setTodo(todo);        
        //navigate(`/todo/type/${todo.typeId}`);
        window.history.back();
      }).catch((e) => { 
        handleError(e, navigate);
      });      
    }
    const handleBack = (e) => {
      e.preventDefault();
      window.history.back();
    }
    
  return (
    <div className="container">
        <div className='container-header'>
            <button className="todo-btn" type="button" onClick={handleBack}>
              <span>&larr;</span>
            </button>
        </div>
        <div className='input-container'>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label htmlFor="txtName">Title:</label>
                <textarea rows="3" className='todo-input' maxLength={250} readOnly={!noteUser.isEndUser}  name="name" id="txtName" required value={name} onChange={(e)=>{setName( e.target.value)}} ></textarea>
                <label className='err'>Please keep the title simple.</label>
            </div>
            <div className="form-group">
                <label htmlFor="txtDesc">Description:</label>
                <textarea rows="6" className='todo-input' readOnly={!noteUser.isEndUser}  name="description" id="txtDesc" required value={desc} onChange={(e)=>{setDesc( e.target.value)}} ></textarea>
                <label className='err'>Special characters are not allowed.</label>
            </div>
            <div className="form-group-multiple">
              <div>
                <input type="checkbox" disabled={!noteUser.isEndUser} name="done" id="chkDone" checked={done} onChange={(e)=>{setDone(e.target.checked)}} />
                <label htmlFor="chkDone">Task finished</label>
              </div>
              <div>
                <input type="checkbox" disabled={!noteUser.isEndUser} name="private" id="chkPrivate" checked={priv} onChange={(e)=>{setPrivate(e.target.checked)}} />
                <label htmlFor="chkPrivate">Private note only</label>
              </div>
            </div>
            <div className="status-group">
            <label >***Created on {localeDate(todo?.date) }
              { (!done) &&  <> and yet to start</>}
              { (done) &&  <> and finished { compareDate(todo?.date,todo?.modifiedOn) ? <></> : <> on {localeDate(todo?.modifiedOn)}</>} </>}
              {(noteUser.isEndUser) ? <>.</> : <> by {todo?.u_Name}</>}
            </label>
            </div>
            { (!noteUser.isEndUser) ? <></> : 
            <div className="form-footer">
              <div className='align-right'>
                <button className="todo-btn" type="submit" disabled={mute} >
                  <span>Save</span>
                </button>
              </div>
            </div>
            }
          </form>
        </div>
        { (todo?.public) ?  
          <Comment comments={comments}
              setComments={setComments}
              isCreator={noteUser.isEndUser}
              isNote={false}
              user={user}
              taskId={todo?.taskId} /> : <></> }
    </div>
  )
}
