import axios from 'axios';
import { useEffect,  useContext } from 'react';
import GroupSelector from "../../Group/selector";
import { useNavigate, Link } from "react-router-dom";

export default function Dashboard(params) {
    const { user, loggedIn, checkLoginState, setUser } = useContext(params.context);
    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const navigate = useNavigate();


    useEffect( () => {
      if(!user) return;
      if(!loggedIn) {
        navigate('/signin');
      }
    }, [user]); // Runs only once

    const handleNavigation = (group) => {
      user.lastGroupId = group.groupId;
      user.lastGroupName =  group.name;
      user.lastGroup =  group;
      setUser(user);
      navigate(`/this-week`);
    }
  
    const handleLogout = async () => {
      try {
        await axios.post(`${serverUrl}/auth/logout`);
        // Check login state again
        checkLoginState();
      } catch (err) {
        console.error(err);
      }
    }
  
    return (
      <div className="empty-container">
      <div className='welcome-header'>
        <img src={user?.picture} alt={user?.email} />
        <h3>Hello {user?.name}!</h3>
        <p>
          Please pick a group from the list below. 
        </p>
        <button className="btn hidden" onClick={handleLogout} >Logout</button>
      </div>
      <div className='input-container'>
        <GroupSelector groupChanged={handleNavigation}/>
      </div>
      <div className='welcome-footer'>
        <p><a href='/privacy'>Privacy Policy</a></p>
      </div>
      </div>
    )
  }