import './index.css';
import { React } from 'react';
import { Link } from "react-router-dom";

export default function Sidebar() {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const imgBase = `${serverUrl}/imgs/`;

  return (
    <div className="sidenav">
        <Link to="/this-week">
          <img src={imgBase + 'calendar-week.svg'} alt='This Week' width={'30px'} height={'30px'} />
        </Link>
        <Link to="/todo">✎</Link>
        <Link to="/note">
          <img src={imgBase + 'notepad.svg'} alt='Group' width={'30px'} height={'30px'} />
        </Link>
        <Link to="/time">&#8461;</Link>
        <Link to="/settings">⚙</Link>
    </div>
  )
}
