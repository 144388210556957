import axios from 'axios';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Logon(params) {
  
  const { user, loggedIn } = useContext(params.context);
  const navigate = useNavigate();
  const imgBase = process.env.PUBLIC_URL;

  useEffect( () => {
    if(!user) return;
    if(loggedIn) {
      navigate('/dashboard');
    }
  }, [user]); // Runs only once
  
  const handleLogin = async () => {
    const serverUrl = process.env.REACT_APP_SERVER_URL;

    try {
      // Gets authentication url from backend server
      const { data: { url } } = await axios.get(`${serverUrl}/auth/url`);
      if( !serverUrl.includes('demo') && !serverUrl.includes('localhost')){
        // Navigate to consent screen
        window.location.assign(url);
      }
    } catch (err) {
      console.error(err);
    }
  }
  const testError = () => {
    navigate('/error', {state:{errMessage:"Hello There this is a test error."}});
  }

  
  return <div className="empty-container">
    <div className='welcome-header'>
      <img src={imgBase + '/icon.png'} alt='Tiny Todo' width={'100px'} height={'100px'} />
      <h3>Sign-in to Tiny Todo!</h3>
      <p>
        The Tiny Todo web app only allows access to users with any of the accounts below. Please choose an account from the options provided.
      </p>
      <p>
        <img id='base64image' style={{cursor:'pointer'}} onClick={handleLogin}  src={`${imgBase}${"/google-signin.jpg"}` } title="Sign's in with google account" />
      </p>
      <p>-or-</p>
      <p className='disabled'>
        <img id='base64image' src={`${imgBase}${"/facebook-signin.jpg"}` } title="Sign's in with facebook account" />
      </p>
      <p>-or-</p>
      <p className='disabled'>
        <img id='base64image' src={`${imgBase}${"/twitter-signin.jpg"}` } title="Sign's in with twitter account" />
      </p>
      <p>-or-</p>
      <p className='disabled'>
        <img id='base64image' src={`${imgBase}${"/linkedin-signin.jpg"}` } title="Sign's in with Linkedin account" />
      </p>
    </div>
    <div className='welcome-footer'>
      <p><a href='/privacy'>Privacy Policy</a></p>
    </div>
  </div>

}
  