import axios from 'axios';
import { useNavigate, Link } from "react-router-dom";

export default function Home() {
    const imgBase = process.env.PUBLIC_URL;
  
    return (
      <div className="empty-container">
      <div className='welcome-header'>
        <img src={imgBase + '/icon.png'} alt='Tiny Todo' width={'100px'} height={'100px'} />
        <h3>Welcome to Tiny Todo!</h3>
        <p style={{textAlign:'left'}}>
          The Tiny Todo is a web app that helps in adding and maintaining a todo list. By serving the purpose as a todo, the app also allows users to share their todos & notes with other users within their group the user is associated with.
          Other users within the group may help with the shared todos by adding their comments.
        </p>
        <Link to={`/signin`} >
          <button type='button' className="btn" >Sign in</button>
        </Link>
      </div>
      <div className='welcome-footer'>
        <p><a href='/privacy'>Privacy Policy</a></p>
      </div>
      </div>
    )
  }