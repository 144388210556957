import { useState, useEffect, useContext } from "react";
import axios from 'axios';
import {  handleError } from '../../../lib/common';
import { Link } from "react-router-dom";

export default function Notes(parameters) {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const { user } = useContext(parameters.context);

  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const noteAPI = `${serverUrl}/notes-summary`;
  const typeAPI = `${serverUrl}/types-data?for=notes`;
  const typeImageAPI = `${serverUrl}/type-icon?type=`;

  useEffect( () => {
    let types = [];
    if(!user) return;

     axios.get(typeAPI, {params:{groupid:user.lastGroupId}}).then(x => {
      types = x.data;
      return axios.get(noteAPI, {params:{groupid:user.lastGroupId}});
     }).then(y=>{
      let summary = [];
      // Lets display the types available first
      (types || []).sort(x => x.typeId).forEach(t => {
        let cnt = (y.data||[]).find(x=>x.typeId == t.typeId);
        summary.push({typeId: t.typeId, name: t.name, count: cnt ? cnt.count : 0});
      });

      setList( summary);
      setLoading( false);
    }).catch((e) => { 
      setLoading( false);
      handleError(e /*, navigate*/);
    });
  }, [typeAPI, user]); // Runs only once

if (loading) {
  return (<div className="container">
      <div>Notes</div>
      <div>Loading notes...</div>
      </div>
    )
} else {
  return (
    <div className="container">
      <div>Notes</div>
      <div className='list-container'>
      {list.map((itm) => {
        return (
        <Link key={itm.typeId} className='list-item' to={`/note/type/${itm.typeId}`} >
          <img style={{display:'block', width:'50px', height:'50px'}} id='base64image' src={`${typeImageAPI}${itm.typeId}` } title={`${itm.name}` } />
          {(itm.count > 0) ? <label>{itm.count}</label> : <></>}
        </Link>
        )})}
      </div>
    </div>
  )
}
};
