import React, {useState, useEffect, useContext, useRef} from 'react'
import Comment from '../../Comment';
import { handleError, localeDate, parseDate, encodeCheck } from '../../../lib/common'
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function NoteForm(parameters) {
    const params = useParams();
    const navigate = useNavigate();
    const { user } = useContext(parameters.context);
    const commentForm = useRef();

    let emptyNote = {
      noteId: 0,
      groupId:user ? user.lastGroupId : 0,
      name: "",
      date: new Date(),
      typeId:params.type,
      description: '',
      modifiedOn: new Date(),
      public: false,
      createdBy: '',
      comments: []
    };

    const [note, setNote] = useState( emptyNote);
    const [name, setName] = useState( '');
    const [desc, setDesc] = useState( '');
    const [priv, setPrivate] = useState( true);
	const [mute, setMuted] = useState( false);
    const [comments, setComments] = useState([]);

    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const noteAPI = `${serverUrl}/notes-data?type=${params.type}&id=${params.id}`;
    const [loading, setLoading] = useState(true);
    const [noteUser, setNoteUser] = useState({userId:-1, name:'', isEndUser: true});

  useEffect(() => {
    if(!user) return;

    if(params.id && params.id != '0'){
      axios.get(noteAPI, {params:{groupid:user.lastGroupId}}).then(async y=>{
        if(y.data && y.data.length > 0) {
          let note = y.data[0];
          setNote(note);
          setName( decodeURI(note.name) );
          setDesc( decodeURI( note.description) );
          setPrivate(!note.public);
          setComments( (note.comments||[]).sort( x => parseDate(x.commentedOn) ) );

          noteUser.userId = user.userId;
          noteUser.name = user.name;
          noteUser.isEndUser = (note.userId == user.userId);
          setNoteUser(noteUser);
        }
        setLoading(false);
      }).catch((e) => { 
        setLoading(false);
        handleError(e, navigate);
      });
    } 
    else {
      setLoading(false);
    }
  }, [user]); // Runs only once


    /**
     * @param {React.FormEvent<HTMLButtonElement>} e 
     */
    const handleSubmit = (e) => {
      e.preventDefault();

      // Validation part
      e.target.children[0].classList.remove('err');
      e.target.children[1].classList.remove('err');
      if(/[{<[]+/.test(name)) { e.target.children[0].classList.add('err'); return; }
      if(/[{<[]+/.test(desc)) { e.target.children[1].classList.add('err'); return; }

      // Long name validation 
      note.name = encodeCheck(name);
      if(note.name.length > 250 ) { e.target.children[0].classList.add('err'); return; }

      note.description= encodeCheck( desc );
      note.public = priv ? false: true;
      if(!note.groupId) 
        note.groupId = user ? user.lastGroupId : 0;// refresh bug fix
      setMuted(true);

      axios.post(`${serverUrl}/include-note`,note).then(y=>{
        alert(`Note ${note.noteId <= 0 ? 'created' : 'updated' } successfully!`);
        note.noteId = y.data.noteId;
        note.modifiedOn = new Date();
        setMuted(false);
        setNote(note);
        navigate(`/note/type/${note.typeId}`);
      }).catch((e) => { 
        handleError(e, navigate);
      });      
    }
    const handleBack = (e) => {
      e.preventDefault();
      window.history.back();
    }
    
  return (
    <div className="container">
        <div className='container-header'>
            <button className="todo-btn" type="button" onClick={handleBack}>
              <span>&larr;</span>
            </button>
        </div>
        <div className='input-container'>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label htmlFor="txtName">Title:</label>
                <textarea rows="3" className='todo-input' readOnly={!noteUser.isEndUser} maxLength={250}  name="name" id="txtName" required value={name} onChange={(e)=>{setName( e.target.value)}} ></textarea>
                <label className='err'>Please keep the title simple.</label>
            </div>
            <div className="form-group">
                <label htmlFor="txtDesc">Description:</label>
                <textarea rows="6" className='todo-input' readOnly={!noteUser.isEndUser}   name="description" id="txtDesc" required value={desc} onChange={(e)=>{setDesc( e.target.value)}} ></textarea>
                <label className='err'>Special characters are not allowed.</label>
            </div>
            <div className="form-group">
                <input type="checkbox" disabled={!noteUser.isEndUser}  name="private" id="chkPrivate" checked={priv} onChange={(e)=>{setPrivate(e.target.checked)}} />
                <label htmlFor="chkPrivate">Private note only</label>
            </div>
            <div className="status-group">
              <label >***Created on {localeDate(note?.date)}</label>
              { localeDate(note?.date) != localeDate(note?.modifiedOn) ? <label> and last updated on {localeDate(note?.modifiedOn)}</label> : <label></label> }
              {(noteUser.isEndUser) ? <label>.</label> : <label> by {note?.u_Name}</label>}
            </div>
            { (!noteUser.isEndUser) ? <></> : 
            <div className="form-footer">
              <div className='align-right'>
                <button className="todo-btn" type="submit" disabled={mute} >
                  <span>Save</span>
                </button>
              </div>
            </div>
            }
          </form>
        </div>
        { (note?.public) ?  
          <Comment comments={comments}
              setComments={setComments}
              isCreator={noteUser.isEndUser}
              isNote={true}
              user={user}
              taskId={note?.noteId} /> : <></> }
    </div>
  )
}
