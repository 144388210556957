import React, { useState } from 'react'

import { useNavigate, useParams } from "react-router-dom";


// https://coolors.co/eac8ca-f2d5f8-e6c0e9-bfabcb-8d89a6

/**
 * 
 * @param {Array} todoTags 
 * @returns 
 */
export default function NoteItem(params) {
  let data = params.data;
  
  const navigate = useNavigate();
  const urlParams = useParams();

  const onEditNote = (e) =>  {
    navigate(`/note/type/${urlParams.type}/${data.noteId}`);
  }
  
  return (
    <div className="todo-item">
      <div className="text-todo">
          <label >
          <span><p>{decodeURI( data.name)}{(data?.public) ? '★': '' }</p></span>
          </label>
      </div>
      
      <div className="text-next">
        <button className="todo-btn"  type="button" onClick={(e) =>onEditNote(e)} >&rarr;</button>
      </div>
    </div>
  );
}
