import React, {useState, useEffect, useContext} from 'react'
import { handleError, localeDate, parseDate, compareDate, printDate } from '../../../lib/common'
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import Comment from '../../Comment';

export default function HistoryForm(parameters) {
    const params = useParams();
    const navigate = useNavigate();
    const { user } = useContext(parameters.context);

    const [todo, setTodo] = useState( null);
    const [name, setName] = useState( '');
    const [desc, setDesc] = useState( '');
    const [priv, setPriv] = useState( true);
	  const [mute, setMuted] = useState( false);
    const [comments, setComments] = useState([]);

    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const taskAPI = `${serverUrl}/history-data?type=${params.type}&id=${params.id}`;
    const [loading, setLoading] = useState(true);
    const [noteUser, setNoteUser] = useState({userId:-1, name:'', isEndUser: true});

  useEffect(() => {
    if(!user) return;

    if(params.id && params.id != '0'){
      axios.get(taskAPI, {params:{groupid:user.lastGroupId}}).then(async y=>{
        if(y.data && y.data.length > 0) {
          let toda = y.data[0];
          setTodo(toda);
          setName(decodeURI(toda.name));
          setPriv(!toda.public);
          setDesc(decodeURI(toda.description));
          setComments( (toda.comments||[]).sort( x => parseDate(x.commentedOn) ) );
          
          noteUser.userId = user.userId;
          noteUser.name = user.name;
          noteUser.isEndUser = (toda.userId == user.userId);
          setNoteUser(noteUser);
        }
        setLoading(() => false);
      }).catch((e) => { 
        setLoading(() => false);
        handleError(e, navigate);
      });
    } 
    else {
      setLoading(() => false);
    }
  }, [user]); // Runs only once


    /**
     * @param {React.FormEvent<HTMLButtonElement>} e 
     */
    const handleSubmit = (e) => {
      e.preventDefault();
      setMuted(true);

      todo.taskId = 0;// re-create the taskId
      todo.statusId = 0;
      todo.date = new Date();
      todo.modifiedOn = null;

      axios.post(`${serverUrl}/include-task`,todo).then(y=>{
        setMuted(false);
        setTodo(todo);
        alert('Todo has been recreated.');
        //navigate(`/time/type/${todo.typeId}`);
        window.history.back();
      }).catch((e) => {
        handleError(e, navigate);
      });
    }
    const handleBack = (e) => {
      e.preventDefault();
      window.history.back();
    }
    const handleDelete = (e) => {
      e.preventDefault();
      
      axios.post(`${serverUrl}/delete-history`,{ taskId:todo.taskId, isDeleted:(todo.isDeleted? false:true) }).then(y=>{
        if(todo.isDeleted)
          alert('The item is now revoked.');
        else
          alert('This item will get deleted next week.');
        window.history.back();
      }).catch((e) => { 
        handleError(e, navigate);
      });
    }
    
  return (
    <div className="container">
        <div className='container-header'>
            <button className="todo-btn" type="button" onClick={handleBack}>
              <span>&larr;</span>
            </button>
            {todo?.year && !todo?.public && !user?.lastGroup?.public ? 
            <>
            <label>{printDate(todo?.date,todo?.year)}</label>
            <button className="todo-btn" type="button" onClick={handleDelete}>
              <span>X</span>
            </button>
            </> : <></>
            }
        </div>
        <div className='input-container'>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label htmlFor="txtName">Title:</label>
                <textarea readOnly={true} className='todo-input' maxLength={250}  rows="3"  name="name" id="txtName" defaultValue={name} ></textarea>
            </div>
            <div className="form-group">
                <label htmlFor="txtDesc">Description:</label>
                <textarea readOnly={true} className='todo-input'   rows="6"  name="description" id="txtDesc"  defaultValue={desc}  ></textarea>
            </div>
            <div className="form-group">
                <input type="checkbox" disabled={true} name="private" id="chkPrivate" defaultChecked={priv} />
                <label htmlFor="chkPrivate">Private note only</label>
            </div>
            <div className="status-group">
              <label >***Created  on {localeDate(todo?.date) } { compareDate(todo?.date,todo?.modifiedOn) ? <></> : <> and last updated on {localeDate(todo?.modifiedOn)}</>}.</label>
              {(noteUser.isEndUser) ? <label>.</label> : <label> by {todo?.u_Name}</label>}
            </div>
            { (!noteUser.isEndUser) ? <></> : 
            <div className="form-footer">
              <div className='align-right'>
                <button className="todo-btn" type="submit" disabled={mute} >
                  <span>Redo</span>
                </button>
              </div>
            </div>
            }
          </form>
        </div>
        { (todo?.public) ?  
          <Comment comments={comments}
              setComments={null}
              isCreator={noteUser.isEndUser}
              isNote={false}
              user={user}
              taskId={todo?.taskId} /> : <></> }
    </div>
  )
}
