import React, { useState } from 'react'
import './index.css'
import { useNavigate } from "react-router-dom";
import { handleError, printDate } from '../../../lib/common'
import axios from 'axios';

// https://coolors.co/eac8ca-f2d5f8-e6c0e9-bfabcb-8d89a6

/**
 * 
 * @param {Array} todoTags 
 * @returns 
 */
export default function TodoItem(params) {
  let data = params.data;
  let isComplete = data.statusId == 2;
  let todoUid = data.taskId;
  let userId = params.userId;
  let updateIdFunction = params.updateId;
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const imageAPI = `${serverUrl}/type-icon?type=`;

  const navigate = useNavigate();
  const [finished, setFinished] = useState(isComplete);

  /**
   * 
   * @param {React.ChangeEvent<HTMLInputElement>} e 
   */
  const onCheckingTodo = (e) =>  {
    updateTodo( e.target.checked, data)
    setFinished(!finished);
  }
  const onEditTodo = (e) =>  {
      navigate(`/todo/type/${data.typeId}/${data.taskId}`, {state: (data.taskId < 0) ? data : null} );
  }
  const addTodo = (todo) => {
    axios.post(`${serverUrl}/include-task`,todo).then((y) => {
      if (todo.taskId <= 0) {
        // add the new todo to beginning of todos array
        updateIdFunction(todo.taskId, y.data.taskId);
      }
    });
  }

  const updateTodo = ( checked, todoTask) => {
    let k = checked ? 2 : 1;
    todoTask.statusId = k;
    
    if(todoTask.taskId <= 0){
      addTodo(todoTask);
    }
    else{
      axios.post(`${serverUrl}/check-task`,{ taskId:todoTask.taskId, statusId: k }).then(y=>{
        //console.log( 'axios response', y);
      }).catch((e) => { 
        handleError(e, navigate);
      });
    }
  }
  return (
    <div className="todo-item">
      <div className="text-todo">
          <input className="checkbox-todo" id={todoUid} type="checkbox" disabled={data?.userId != userId} checked={finished} onChange={(e) => onCheckingTodo(e)} />
          <label htmlFor={todoUid}>
            <span className="text-todo"><p>{ decodeURI( data.name)}{(data?.public) ? '★': '' } - {printDate(data?.date,data?.year)}</p></span>
          </label>
          {params.showType ? <img src={`${imageAPI}${data?.typeId}` }  /> : <></>}
      </div>
      <div className="text-next">
        <button className="todo-btn"  type="button" onClick={(e) =>onEditTodo(e)} >&rarr;</button>
      </div>
    </div>
  );
}
