import { useState, useEffect,useContext } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import {  handleError } from '../../../lib/common';

export default function Todo(parameters) {
  const [all, showAll] = useState(false);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const { user } = useContext(parameters.context);

  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const taskAPI = `${serverUrl}/tasks-summary`;
  const scheduleAPI = `${serverUrl}/scheduled-tasks-summary`;
  const scheduleTypeImageAPI = `${serverUrl}/type-icon?type=`;
  const typeAPI = `${serverUrl}/types-data?for=notes`;

  useEffect( () => {
    if(!user) return;

    let types = [];
    let summary = [];
    let data =[];
    axios.get(typeAPI, {params:{groupid:user.lastGroupId}}).then(x => {
      types = (x.data || []).sort(x => x.typeId);
      // Lets display the types available first
      types.forEach(t => {
        summary.push({typeId: t.typeId, name: t.name, count: 0, open: 0});
      });

      setList( summary);
      return axios.get(taskAPI, {params:{groupid:user.lastGroupId}});
    }).then(y=>{
      data = (y.data||[]);
      // Lets display the task count
      types.sort(x => x.typeId).forEach(t => {
        let cnt = data.find(x=>x.typeId == t.typeId);
        let sumItem = summary.find(x=>x.typeId == t.typeId);
        sumItem.count = cnt ? cnt.count : 0;
        sumItem.open = cnt ? cnt.open : 0;
      });

      setList( summary);
      if(!user.lastGroup?.isDefault)
        return Promise.resolve( {data:[]});
      else
        return axios.get(scheduleAPI, {params:{groupid:user.lastGroupId}});
    }).then(z=>{
      data = (z.data||[]);
      // Lets display the scheduled task count
      types.sort(x => x.typeId).forEach(t => {
        let cnt = data.find(x=>x.typeId == t.typeId);
        let sumItem = summary.find(x=>x.typeId == t.typeId);
        sumItem.count += cnt ? cnt.count : 0;
        sumItem.open += cnt ? cnt.open : 0;
      });

      setList( summary);
      setLoading( false);
    }).catch((e) => { 
      setLoading( false);
      handleError(e /*, navigate*/);
    });
  }, [taskAPI, scheduleAPI, user]); // Runs only once

  const handleShowAll = (e) => {
    e.preventDefault();
    showAll(true);
  };

if (loading) {
  return (<div className="container">
      <div>Todo</div>
      <div>Loading Todos...</div>
      </div>
    )
} else {
  return (
    <div className="container">
      <div>Todo</div>
      <div className='list-container'>
      {list.filter(x=>x.count > 0 || all).map((itm) => 
          <Link key={itm.typeId} className='list-item' to={`/todo/type/${itm.typeId}`} >
            <img style={{display:'block', width:'50px', height:'50px'}} id='base64image' src={`${scheduleTypeImageAPI}${itm.typeId}` } title={`${itm.name}` }  />
            {
            (itm.open > 0 && itm.open < itm.count ) ? <label className="openclosed">{itm.open}</label> : 
             ((itm.open > 0 && itm.open == itm.count ) ? <label>{itm.open}</label> : 
             ((itm.open == 0 && itm.count > 0 ) ? <label className="allclosed">{itm.count}</label> : 
             <></>))
            }
          </Link>)
      }
      {(!all) ? <a href="#" key={0} className="list-item" onClick={handleShowAll}>***</a> : <></>}
      </div>
    </div>
  )
}
};
