import React from 'react';
import { Home, Sidebar,Todo,Notes,Time, Member, Group, TodoList,TodoForm, Root, Dashboard, Logon, Callback,  Error, NoteList, NoteForm, HistoryForm, HistoryList, ReviewList, PrivacyPolicy } from './components';
import './index.css'
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState, createContext,  useCallback } from 'react';

// Ensures cookie is sent
axios.defaults.withCredentials = true;

const serverUrl = process.env.REACT_APP_SERVER_URL;


const AuthContext = createContext();


const AuthContextProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(null);
  const [user, setUser] = useState(null);
  const [scanned, setScanned] = useState(false);

  const checkLoginState = useCallback(async () => {
    try {
      let k = await axios.get(`${serverUrl}/auth/logged_in`);
      const { data: { loggedIn: logged_in, user: usr }} = k;
      setLoggedIn(logged_in);
      
      if(logged_in){
        let u = await axios.get(`${serverUrl}/auth/user`);
        //console.log('Setting User information', u);
        setUser(u.data);
      }
      else{
        usr && setUser(usr);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setScanned(true);
    }
  }, []);

  useEffect(() => {
    checkLoginState();
  }, [checkLoginState]);

  return (
    <AuthContext.Provider value={{ loggedIn, checkLoginState, user, setUser, scanned }}>
      {children}
    </AuthContext.Provider>
  );
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root context={AuthContext} />,
    children: [
      { path: '', element: <Home />, },
      { path: 'signin', element: <Logon context={AuthContext} />, },
      { path: 'todo/type/:type',element: <><Sidebar /><Group context={AuthContext} /><TodoList context={AuthContext} /></>,},
      { path: 'todo/type/:type/:id', element: <><Sidebar /><Group context={AuthContext} /><TodoForm context={AuthContext} /></> },
      { path: 'todo', element: <><Sidebar /><Group context={AuthContext} /><Todo context={AuthContext} /></> },
      { path: 'note', element: <><Sidebar /><Group context={AuthContext} /><Notes context={AuthContext} /></> },
      { path: 'note/type/:type', element: <><Sidebar /><Group context={AuthContext} /><NoteList context={AuthContext} /></> },
      { path: 'note/type/:type/:id', element: <><Sidebar /><Group context={AuthContext} /><NoteForm context={AuthContext} /></> },
      { path: 'time', element: <><Sidebar /><Group context={AuthContext} /><Time context={AuthContext} /></> },
      { path: 'settings', element: <><Sidebar /><Group context={AuthContext} /><Member context={AuthContext} /></> },
      { path: 'this-week', element: <><Sidebar /><Group context={AuthContext} /><ReviewList context={AuthContext} /></> },
      { path: 'time/type/:type', element: <><Sidebar /><Group context={AuthContext} /><HistoryList context={AuthContext} /></> },
      { path: 'time/type/:type/:id', element: <><Sidebar /><Group context={AuthContext} /><HistoryForm context={AuthContext} /></> },
      { path: 'dashboard', element: <Dashboard context={AuthContext} /> },
      { path: 'privacy', element: <PrivacyPolicy /> },
      { path: 'error', element: <Error /> },
    ],
  },
  {
    path: '/auth/callback', // google will redirect here
    element: <Callback context={AuthContext} />,
  }
]);

function App() {
  return (
    <header className="head">
      <>
      <AuthContextProvider>
      <div className="body">
        <RouterProvider router={router} />
      </div>
      </AuthContextProvider>
      </>
    </header>
  );
}

export default App;
