import { useState, useEffect, useContext, useRef } from "react";
import axios from 'axios';
import '../index.css'
import {  handleError } from '../../../lib/common';

export default function Member(parameters) {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [groups, setGroups] = useState([]);
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const memberAPI = `${serverUrl}/members-data`;
  const groupsAPI = `${serverUrl}/groups-data`;
  const groupForm = useRef();
  const { user } = useContext(parameters.context);

  useEffect( () => {
    if(!user) return;
     axios.get(memberAPI, {params:{groupid:user.lastGroupId}}).then(y=>{
      let tmpArr = y.data;
      setList( tmpArr);
      return axios.get(groupsAPI);
    }).then(z=>{
      setGroups( (z.data||[]) );
      setLoading( false);
    }).catch((e) => { 
      setLoading( false);
      handleError(e /*, navigate*/);
    });
  }, [memberAPI,user]); // Runs only once

  const showGroupForm = (e) => {
    let txt = groupForm.current[0];
    txt.parentElement.className = '';
    txt.parentElement.nextSibling.className = 'hidden';
    txt.value = '';
    txt.focus();
  };
  const cancelGroupForm = (e) => {
    let txt = groupForm.current[0];
    txt.parentElement.className = 'hidden';
    txt.parentElement.nextSibling.className = 'align-right';
    txt.parentElement.classList.remove('err');
  };
  const handleAdd = (e) => {
    e.preventDefault();

    // Validation
    let txt = groupForm.current[0];
    txt.parentElement.classList.remove('err');
    if(/[{<[]+/.test(txt.value)) { 
      txt.parentElement.classList.add('err'); 
      return; 
    }

    let data = {
      groupId: 0,
      userId: 0,
      name: txt.value
    };

    axios.post(`${serverUrl}/include-member`,data).then(y=>{
      if(y.data){
        data.groupId = y.data.groupId;
        setGroups(c => [...c, data]);
        cancelGroupForm(e);
      }
      else {
        txt.parentElement.classList.add('err'); 
        txt.nextSibling.innerText = 'Please verify the group name';
      }
    }).catch((e) => { 
      handleError(e /*, navigate*/);
    });
  }

if (loading) {
  return (<div className="container">
      <div>Members</div>
      <div>Loading members...</div>
      </div>
    )
} else {
  return (
    <div className="container">
      <div>Members</div>
      <div className='iterated-list list-container' >
      {list.length > 0 ? list.map((itm, i) => {
        return (
          <label key={i+1}>{i+1}. {itm.name}</label>
        )}) : <label>This group is public.</label> }
      </div>
      <div style={{marginTop:'20px'}}>Group Requests</div>
      <div className='iterated-list list-container ' >
      {groups.map((itm, i) => {
        return (
          <label key={i+1}>{i+1}. {itm.name} ({itm.isActive ? 'active' : 'disabled'})</label>
        )})}
      </div>
      <div className="comment-container" >
        <form onSubmit={handleAdd} ref={groupForm} >
          <div className="form-group" >
            <div className='hidden'>
              <label>Enter the external group name</label>
              <input type="text" className='todo-input' maxLength={100}   name="group" id="txtGroup" required ></input>
              <label className='err'>Special characters are not allowed.</label>
              <div className='align-right' style={{marginTop:'10px'}}>
              <button className="todo-btn" type="submit">
                <span>Find & Add</span>
              </button>
              <button className="todo-btn" type="button" onClick={cancelGroupForm} >
                <span>Cancel</span>
              </button>
              </div>
              <p style={{marginTop:'10px'}}><i>Note : For associating yourself with a group, click "Find & Add". For creating a new group, please write an email to support@remindme.club with all the list of members along with their email Ids </i></p>
            </div>
            <div className='align-right'>
              <button className="todo-btn" type="button" onClick={showGroupForm} >
                <span>+ External Group</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
};
