import { useContext, useEffect } from 'react';
import { Link } from "react-router-dom";

export default function Group(param) {
  const { user, scanned } = useContext(param.context);
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const imgBase = `${serverUrl}/imgs/`;

  useEffect(() => {
    if(scanned && !user){
      window.location.assign('/');
    }
  }, [user,scanned]);

  if(!user)
    return (<></>)
  else
    return (<div className='group-header'>
        <Link to="/dashboard">
          <img src={imgBase + 'users-more.svg'} alt='Group' width={'30px'} height={'30px'} />
          <label>{user.lastGroupName}</label>
        </Link>
      </div>)
};
