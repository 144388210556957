import axios from 'axios';
import { useEffect, useRef,  useContext } from 'react';
import { useNavigate } from 'react-router-dom';


export default function Callback(params)  {
  
  const called = useRef(false);
  
  const { checkLoginState, loggedIn } = useContext(params.context);
  const navigate = useNavigate();
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    (async () => {
      if (loggedIn === false) {
        try {
          if (called.current) return; // prevent rerender caused by StrictMode
          called.current = true;
          await axios.get(`${serverUrl}/auth/token${window.location.search}`)
          .then((res)=>{
            //console.log('token response: ', res);
          });
          
          checkLoginState();
          axios.post(`${serverUrl}/auth/entry_log`, null).then(()=>{}).catch((e) => {
            console.log(e);
          });
          navigate('/dashboard');
        } catch (err) {
          console.error(err);
          navigate('/error', {state: err} );
        }
      } else if (loggedIn === true) {
        axios.post(`${serverUrl}/auth/entry_log`, null).then(()=>{}).catch((e) => {
          console.log(e);
        });
        navigate('/dashboard');
      }
    })();
  }, [checkLoginState, loggedIn, serverUrl, navigate])
  return <div className="empty-container">
  <div className='welcome-header'>
    <p>
      Please wait while the page is getting redirected...
    </p>
  </div>
</div>
};