import { useRef } from 'react';
import { handleError, localeDate, nameNotation, colorNameByNotation, parseDate, compareDate, encodeCheck } from '../../lib/common';
import axios from 'axios';

export default function Comment(param) {
  let comments = param.comments;
  let setComments = param.setComments;
  let isCreator = param.isCreator;
  let isNote = param.isNote;
  let user = param.user;
  let taskId = param.taskId;
  let cantAdd = !param.setComments;

  const commentForm = useRef();
  const serverUrl = process.env.REACT_APP_SERVER_URL;

  const showCommentForm = (e) => {
    let txt = commentForm.current["comment"];
    txt.parentElement.parentElement.className = 'align-right';
    txt.parentElement.parentElement.nextSibling.className = 'hidden';
    txt.value = '';
    txt.focus();
  };
  const cancelCommentForm = (e) => {
    let txt = commentForm.current["comment"];
    txt.parentElement.parentElement.className = 'hidden'; 
    txt.parentElement.parentElement.nextSibling.className = 'align-right';
    txt.parentElement.classList.remove('err');
  };
  const handleComment = (e) => {
    e.preventDefault();
    
    // Validation
    let txt = commentForm.current["comment"];
    txt.parentElement.classList.remove('err');
    if(/[{<[]+/.test(txt.value)) { 
      txt.parentElement.classList.add('err'); 
      return; 
    }

    let cmment = {
      commentId: 0,
      taskId: taskId,
      description: encodeCheck(txt.value),
      commentedOn: new Date(),
      isNote: isNote ? 1 : 0,
      userId: user.userId,
      u_Name: 'you'
    };

    axios.post(`${serverUrl}/include-comment`,cmment).then(y=>{
      cmment.commentId = y.data.commentId;
      setComments(c => [...c, cmment]);
      cancelCommentForm(e);
    })
    //.catch((e) => { handleError(e, navigate); });
  }

  const updateComment = (e) => {
    e.target.previousSibling.className = 'hidden';
    e.target.className = 'hidden';
    e.target.nextSibling.className = '';
  }

  const commentHtml = (item) => {
    let desc = decodeURI(item.description);

    return (desc.length > 100 && !item.showMore ) ? 
    <>
    <label>{desc.substring(0,100)}</label>
    <button type='button' style={{border:'none',backgroundColor:'inherit'}} onClick={updateComment}>...</button>
    <label className='hidden'>{desc}</label>
    </> : 
    <label>{desc}</label>
  }

  return (
    <div className='comment-container'>
      {
        comments.map((item, i) => (
          <div key={i}  className="comment-item">
            {(item?.userId != user.userId) ? 
            <>
            <div className='profile-image' style={{backgroundColor:colorNameByNotation(nameNotation(item?.u_Name))}}><label>{ nameNotation(item?.u_Name)  }</label> </div>
            <div style={{width:'100%'}}>
              <label className='muted'>{ item?.u_Name } ({localeDate(item?.commentedOn)})</label>
              <div className='comment'>
                { commentHtml(item)}
              </div>
            </div>
            </>
            :<>
            <div className='profile-image'></div>
            <div style={{width:'100%'}}>
              <label className='muted' style={{display: 'flex',justifyContent: 'end'}}>you ({localeDate(item?.commentedOn)})</label>
              <div className='comment'>
                { commentHtml(item)}
              </div>
            </div>
            </>}
          </div>
        ))
      }
      { (isCreator || cantAdd) ? <></> : 
      <form onSubmit={handleComment} ref={commentForm} >
      <div className="form-group" >
        <div className='hidden'>
          <div style={{width:'30px',textAlign:'right'}}>
            <button className='comment-btn' type="button" onClick={cancelCommentForm}>
              <span>x</span>
            </button>
            <button className='comment-btn' type="submit">
              <span>&gt;</span>
            </button>
          </div>
          <div >
            <textarea rows="3" className='todo-input' name="comment" id="txtComment" required ></textarea>
            <label className='err'>Special characters are not allowed.</label>
          </div>
        </div>
        <div className='align-right'>
          <button className="todo-btn" type="button" onClick={showCommentForm} >
            <span>+ Comment</span>
          </button>
        </div>
      </div>
      </form>
      }
    </div>
  )
};
