function handleError(err, navfn = null) {
    if (err && err.response && navfn) {
        if (!err.response.status) {
            navfn('/error', { state: { errMessage: "Unnown error has occurred." } })
        }
        else if (err.response.status === 401) {
            navfn('/error', { state: { errMessage: "User not authorized to view this page." } })
        }
        else if (err.response.status === 500) {
            navfn('/error', { state: { errMessage: "Internal Server Error occurred." } })
        }
        else {
            navfn('/error', { state: { errMessage: "Error:" + err.response.status } })
        }
    } else {
        window.location.assign('/error');
    }
}
function localeDate(str) {
    if (!str) return "";
    return (new Date(str.toString())).toLocaleDateString();
}
function compareDate(str1, str2) {
    if (!str1 || !str2) return false;
    return (new Date(str1.toString())).toLocaleDateString() === (new Date(str2.toString())).toLocaleDateString();
}
function parseDate(str) {
    if (!str) return new Date("01/01/2000");
    return new Date(str.toString());
}
function encodeCheck(desc) {
    if (!desc) return '';

    return (/^[a-z0-9\s\t\r\n_|\\+()!@#$%^&*=?/~`:;'"[\]\-.,]+$/i.test(desc)) ? desc : encodeURI(desc);
}
function printDate(dt, yr) {
    if (!dt) return "";
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let nuDt = new Date(dt);
    let mnth = months[nuDt.getMonth()] + ' ' + nuDt.getDate() + (yr ? ', ' + (yr < 0 ? (-yr) + ' BC' : yr) : '');
    return mnth;
}
function nameNotation(str) {
    if (!str) return "--";
    let k = str[0];
    let lName = str.split(' ')[1];
    if (lName) {
        k += lName[0];
    }
    return k;
}
function colorNameByNotation(notation) {
    if(!notation) return "";
    var colors = [
        'a:darkgreen', 'a:darkmagneta', 'a:darkolivegreen',
        'black', 'blue', 'blueviolet', 'brown',
        'cadetblue', 'choclate', 'cornflowerblue', 
        'darkblue', 'darkcyan', 
        'e:darkorchid', 'e:darkred', 'e:darkslateblue', 
        'firebrick', 'forestgreen', 'fuchsia',
        'gray', 'green',
        'h:darkslategray', 'h:darkturquoise', 
        'indianred', 'indigo', 
        'j:deeppink', 'j:dimgrey', 
        'k:darkviolet','k:dodgerblue',
        'lightseagreen', 'lightslategrey', 'limegreen',
        'magenta', 'maroon', 'mediumblue', 
        'n:navy','n:crimson',
        'orchid','olive','olivedrab',
        'palevioletred','peru','purple',
        'q:mediumorchid', 'q:mediumpurple', 
        'rebeccapurple','red','royalblue',
        'saddlebrown','seagreen','sienna',
        'teal','tomato',
        'u:mediumslateblue','u:mediumvioletred', 
        'v:slateblue','v:slategrey',
        'w:orangered',
        'x:steelblue',
        'y:midnightblue',
        'z:mediumseagreen', 
    ];

    let f = notation.toLowerCase()[0];
    let s = notation.toLowerCase()[1];

    let selection = colors.filter(x=>x.indexOf(f) == 0);
    let count = selection.length;
    if('abcdefghijklm'.includes(s))
        return selection[0].includes(':') ? selection[0].split(':')[1] : selection[0];
    else
        return selection[count-1].includes(':') ? selection[count-1].split(':')[1] : selection[count-1];
}
module.exports = {
    handleError, localeDate, parseDate, compareDate, encodeCheck, printDate, nameNotation, colorNameByNotation
};