import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import NoteItem from '../NoteItem';
import { handleError, parseDate } from '../../../lib/common';
import { useParams,useNavigate } from 'react-router-dom';


export default function NoteList(parameters) {
  const [loading, setLoading] = useState(true);
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [notes, setNotes] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useContext(parameters.context);
  
  const noteAPI = `${serverUrl}/notes-data?type=`+ params.type;
  const imageAPI = `${serverUrl}/type-icon?type=`+ params.type;

  useEffect(() => {
    if(!user) return;
    axios.get(noteAPI, {params:{groupid:user.lastGroupId}}).then(y=>{
      setNotes( (y.data||[]).sort( x => parseDate(x.date) ).reverse() );
      setLoading(false);
    }).catch((e) => { 
      setLoading(false);
      handleError(e, navigate);
    });
  }, [noteAPI, user]); // Runs only once

  const handleBack = (e) => {
    e.preventDefault();
    navigate(`/note`);
  }
  const handleEdit = (e) => {
    e.preventDefault();
    navigate(`/note/type/${params.type}/0`)
  }
  
  if (loading) {
    return (
      <div className="container">
        <div>Loading items...</div>
      </div>
      )
  } else {
    return (
      <div className="container">
        <div className='container-header'>
            <button className="todo-btn" type="button" onClick={handleBack}>
              <span>&larr;</span>
            </button>
            <img style={{display:'block', width:'50px', height:'50px'}} id='base64image' src={`${imageAPI}` } />
            { user?.lastGroup?.public ? 
              <button className="todo-btn" type="button">
                <span>&nbsp;#&nbsp;</span>
              </button> :
              <button className="todo-btn" type="button" onClick={handleEdit}>
                <span>&nbsp;+&nbsp;</span>
              </button>}
        </div>
        <div className='todo-container'>
        {notes.length > 0 ? 
            Array.from(notes).map(item => (
              <NoteItem data={item}  key={item.noteId} />
            ))
           : <>No notes found. Click '+' to add a new note.</>
        }
        </div>
      </div>
    )
  }
  
}
