import { useState, useEffect } from "react";
import axios from 'axios';
import  handleError from '../../lib/common';


export default function GroupSelector(param) {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const groupAPI = `${serverUrl}/groups-data`;
  
  const onSelect = (e, grp) => {
    e.preventDefault();
    axios.post(`${serverUrl}/auth/entry_log`, { },{ params: {groupid:grp.groupId} }).then(()=>{
      param.groupChanged(grp);
    }).catch((e) => {
      console.log(e);
    });
  }

  useEffect( () => {
     axios.get(groupAPI).then(y=>{
      setList( (y.data||[]).filter(x=>x.isActive) );
      setLoading( false);
    }).catch((e) => { 
      handleError(e);
      setLoading( false);
    });
  }, [groupAPI]); // Runs only once

if (loading) {
  return (<>
      <div>Loading Groups...</div>
      </>
    )
} else {
  return (
    <>
      {list.map(itm => 
      <div key={itm.groupId}  className="todo-item">
        <div className="text-todo">
          <p>{itm.name}</p>
        </div>
        <div className="text-next">
          <button className="todo-btn"  type="button" onClick={(e) =>onSelect(e, itm)} >&rarr;</button>
        </div>
      </div>
      )}
    </>
  )
}
};
