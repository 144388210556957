import { useLocation } from 'react-router-dom';

export default function Error() {
  let { state } = useLocation();
  
  let msg = '';
  if(state){
    msg = JSON.stringify(state);
  }

  return (
    <div className="empty-container">
      <div>Error</div>
      <div className='list-container'>
        { (msg) ? <>{msg}</> : 
        <>There is an error while loading the page, this might be temporary. Please try again after some time.</> }
      </div>
      <div><a href='/'>Home</a> </div>
    </div>
  )

};
