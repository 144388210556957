import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import TodoItem from '../TodoItem';
import { handleError, parseDate } from '../../../lib/common'
import { useParams,useNavigate } from 'react-router-dom';


export default function TodoList(parameters) {
  const [loading, setLoading] = useState(true);
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [todos, setTodos] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useContext(parameters.context);
  
  const taskAPI = `${serverUrl}/tasks-data?type=`+ params.type;
  const imageAPI = `${serverUrl}/type-icon?type=`+ params.type;
  const scheduleAPI = `${serverUrl}/scheduled-tasks-data?type=`+ params.type;

  useEffect(() => {
    if(!user) return;
    let data = [];
    axios.get(taskAPI, {params:{groupid:user.lastGroupId}}).then(y=>{
      data = (y.data||[]).sort( x => parseDate(x.date) ).reverse();
      
      if(!user.lastGroup?.isDefault)
        return Promise.resolve( {data:[]});
      else
        return axios.get(scheduleAPI, {params:{groupid:user.lastGroupId}});
    }).then(z=>{
      data.push(...(z.data||[]));
      setTodos( data );
      setLoading(false);
    }).catch((e) => { 
      setLoading(false);
      handleError(e, navigate);
    });
  }, [taskAPI, user]); // Runs only once

  const updateId = (oldId, newId) => {
    // add the new todo to beginning of todos array
    let f = todos.find(x=>x.taskId == oldId);
    f.taskId = newId;
    setTodos(todos);
  }
  const handleBack = (e) => {
    e.preventDefault();
    navigate(`/todo`);
  }
  const handleEdit = (e) => {
    e.preventDefault();
    navigate(`/todo/type/${params.type}/0`)
  }
  if (loading) {
    return (
      <div className="container">
        <div>Loading items...</div>
      </div>
      )
  } else {
    return (
      <div className="container">
        <div className='container-header'>
            <button className="todo-btn" type="button" onClick={handleBack}>
              <span>&larr;</span>
            </button>
            <img style={{display:'block', width:'50px', height:'50px'}} id='base64image' src={`${imageAPI}` } />
            { user?.lastGroup?.public ? 
              <button className="todo-btn" type="button">
                <span>&nbsp;#&nbsp;</span>
              </button> :
              <button className="todo-btn" type="button" onClick={handleEdit}>
                <span>&nbsp;+&nbsp;</span>
              </button>}
        </div>
        <div className='todo-container'>
        {todos.length > 0 ? 
            Array.from(todos).map(item => (
              <TodoItem data={item} userId={user.userId} key={item.taskId} updateId={updateId} />
            ))
           : <>No todos found. Click '+' to add a new todo.</>
        }
        </div>
      </div>
    )
  }
  
}
