import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import { handleError, parseDate, printDate } from '../../../lib/common';
import { useParams,useNavigate } from 'react-router-dom';


export default function HistoryList(parameters) {
  const [loading, setLoading] = useState(true);
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const [times, setHistory] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useContext(parameters.context);
  
  const timeAPI = `${serverUrl}/history-data?type=`+ params.type;
  const typeImageAPI = `${serverUrl}/type-icon?type=`+ params.type;

  useEffect(() => {
    if(!user) return;
    axios.get(timeAPI, {params:{groupid:user.lastGroupId}}).then(y=>{
      setHistory( (y.data||[]).sort( x => parseDate(x.date) ).reverse() );
      setLoading( false);
    }).catch((e) => { 
      setLoading( false);
      handleError(e, navigate);
    });
  }, [timeAPI, user]); // Runs only once

  const handleBack = (e) => {
    e.preventDefault();
    navigate(`/time`);
  }
  const handleEdit = (e) => {
    e.preventDefault();
    navigate(`/time/type/${params.type}/0`)
  }
  const onViewTask = (e,item) =>  {
    navigate(`/time/type/${item.typeId}/${item.taskId}`);
  }
  if (loading) {
    return (
      <div className="container">
        <div>Loading items...</div>
      </div>
      )
  } else {
    return (
      <div className="container">
        <div className='container-header'>
            <button className="todo-btn" type="button" onClick={handleBack}>
              <span>&larr;</span>
            </button>
            <img style={{display:'block', width:'50px', height:'50px'}} id='base64image' src={`${typeImageAPI}` } />
            <button className="todo-btn" type="button">
              <span>&nbsp;#&nbsp;</span>
            </button>
        </div>
        <div className='todo-container'>
        {times.length > 0 ? 
            Array.from(times).map(item => (
              <div className="todo-item" key={item.taskId} >
                <div className="text-todo">
                  {item.isDeleted ? 
                    <label className='removed'>{ decodeURI( item.name)} {(item?.public) ? '★': '' } - {printDate(item.date,item.year)}</label> 
                    :
                    <label>{ decodeURI( item.name)} {(item?.public) ? '★': '' } - {printDate(item.date,item.year)}</label>
                  }
                </div>
                <div className="text-next">
                  <button className="todo-btn"  type="button" onClick={(e) =>onViewTask(e,item)} >&rarr;</button>
                </div>
              </div>
            ))
           : <>No past todos found.</>
        }
        </div>
      </div>
    )
  }
  
}
